export const environment = {
  production: true,
  gtmPruebas: false,
  envName: 'master',
  clientId: 'AvI7MZug3dyIZk1JGFNDm+JOX28ruCgtA9I6d71C4Wg=',
  domain: 'midatacredito.com',
  
  APIEndpoint_nwuser: 'https://buscacredito.midatacredito.com/nwuser-ws',
  APIEndpoint_ldnfosession: 'https://buscacredito.midatacredito.com/ldnfosession-ws',
  APIEndpoint_bank: 'https://buscacredito.midatacredito.com/bank-ws',
  APIEndpoint_authn: 'https://buscacredito.midatacredito.com/authn-ws',
  
  urlLogin: 'https://usuario.midatacredito.com/login?product=bc',
  urlRegister: 'https://usuario.midatacredito.com/registro?product=bc',
  urlProfile: 'https://usuario.midatacredito.com/private/perfil?product=bc',

  	// CloudFront
	enlaces_cloudFront: {
		url: 'https://d1gj9peb9ac569.cloudfront.net/',
		bcPath: 'Buscacredito/produccion',
		terminos_condiciones: 'tyc/terminos_y_condiciones.pdf',
		politica_privacidad: 'tyc/politica_de_tratamiento_de_datos_personales.pdf'
	},

	Auth: {
		validateSession: '/authn/validateSession',
		logOff: '/authn/signOff'
	},
	InfoSession: {
		ecs: '/sessioninfo/ecs'
	},
	Bank: {
		offersListHome: '/ecs/datacash/bank/v1/homeOffers',
		offersListFree: '/ecs/datacash/bank/v1/freeOffers',
		offers: '/ecs/datacash/bank/v1/userOffers',
		offersPreselecta: '/ecs/datacash/bank/v1/userOffersPreselecta',
		offerDetail: '/ecs/datacash/bank/v1/userOfferDetail',
		offerDetailFree: '/ecs/datacash/bank/v1/freeOfferDetail',
		aplicarOferta: '/ecs/datacash/bank/v1/applyOffer',
		applyOfferPreselecta: '/ecs/datacash/bank/v1/applyOfferPreselecta',
		appliedUserOffer: '/ecs/datacash/bank/v1/appliedUserOffer',
		offerHistory: '/ecs/datacash/bank/v1/requesthistory',
		saveOfferEvent: '/ecs/datacash/bank/v1/saveOfferEvent',
		campaignInfo:'/ecs/datacash/bank/v1/campaigninfo',
		customercampaigninfo: '/ecs/datacash/bank/v1/customercampaigninfo',
		applyCampaign: '/ecs/datacash/bank/v1/campaign',
		historyAppliedOffer:'/ecs/datacash/bank/v1/historyAppliedOffer',
    	experienceEvaluation:'/ecs/datacash/bank/v1/experienceEvaluation',
		aplicarOfertaDav:'/ecs/datacash/bank/v1/davivienda'
	},
	NewUser: {
		updateProfile: '/new-user-ws/api/v1/user/updateProfile',
		addCustomerLog: '/termsConditions/addCustomerLog',
	},
	banners: {
		getBanner: '/banners/getBannerNow/BUSCACREDITO'
	},
	cloudFrontBc: {
		categorias: '/categorias.json',
		filter: '/filter.json',
		nitPreselecta: '/nitPreselecta.json'
	},
	country: 'CO',
	slotsBanners : {
		home : '/22857899675/Ad_Unit_BC_Home',
		categorias : '/22857899675/Ad_Unit_BC_Categoria',
		ofertas : '/22857899675/Ad_Unit_BC_Ofertas',
		faq: '/22857899675/Ad_Unit_BC_Faq',
		lista_ofertas_1: '/22857899675/Test_Ad_Unit_BC_Ofertas02',
		lista_ofertas_2: '/22857899675/Test_Ad_Unit_BC_Ofertas03',
	}
};